 
  .admin-home{
    background-color: var(--lightgray);
    overflow-x: hidden;
  }

  .content-section{
    height: 100vh;
  }
 .h-25{
  height: 34rem;
 }
  .recent-card{
    background-color: var(--white);
    border-radius: 16px;
  }
  .recent-card p{
    color: var(--darkgray);
  }
  .w-30{
width: 30%;
  }
  .thumbnail-image{
    border-radius: 16px;
    height: 6rem;
  }
  .graytext{
    color: var(  --darkgray);
  }
  .graytext:hover{
    color: var(  --darkgray);
  }
  @media screen and (min-width:1441px) and (max-width:1920px){
    .thumbnail-image{
      height: 8rem;
    }
  }
  @media screen and (min-width:1921px)  {
    .thumbnail-image{
      height: 10rem;
    }
  }