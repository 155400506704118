.enquiries-card{
    background-color: var(--white);
    border-radius: 16px;
width: 75vw;
 height: auto;
}
.line{
    width: 70.5vw;
   font-weight: 700;
}

 