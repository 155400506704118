 .add-author{
    height: 100vh;
    align-items: center;
    background-position: center center;
}
.add-author .container {
    position: relative;
    width: 30vw;
    background:rgb(250, 249, 249);
    border-radius: 24px;
    color: var( --darkgray);
    transform: translate(-30%, -0%);
  }
  .add-author .author-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
.add-author-card i{
 font-size: 48px;
 position: absolute;
 left: 50%;
 transform: translate(-50%, -0%);
  }
  .add-profile-pic {
    background-color: var(--lightgray);
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  .add-author-card input{
    border-radius: 16px;
    border: none;
    background: var(--lightgray);
  }
  .add-author-card input:focus  {
    border: none;
    outline: none;
  }
  .add-author-card input:active  {
    border: none;
  }
.add-author-buttor{
border: none;
background: #119F93;
border-radius: 20px;
color: var(--white);
margin-left: 25%;
}
.add-author-buttor .checkbox{
    border-radius: 8px;
}
.add-author-card p{
color:var( --errorred2)}
.buttons{
  display: flex;
  justify-content: center;
flex-direction: column;
}
.authors-home{
  background-color: var(--lightgray);
  overflow-x: hidden;
 
}
 .content-section{
  height: 100vh;
}
.neg-mt{
  margin-top: -3rem;
}
.width-80{
  width: 70%;
}
.author-card{
  background-color: var(--white);
  border-radius: 16px;
  min-width: 20vw;
}
.add-author-button{
 width: 160px;
  bottom: 15px;
  right: 20px;
  position: absolute;
  border: none;
background: #119F93;
border-radius: 20px;
color: var(--white);
}
  @media screen and (max-width:1200px){
    .add-author .container {
        width: 50vw;}
  }
  @media screen and (max-width:767px){
    .add-author .container {
        width: 90vw;
        border-radius: 18px;
    }
    .add-author img{
        transform: scale(0.7);
    }
    .add-author-card i{
      font-size: 48px;
      position: absolute;
      left: 45%;
      transform: translate(-15%, -0%);
       }
  }
  