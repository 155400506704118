@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Manrope";
}

:root {
  --babypowder: #fdfffc;
  --lightsilver: #d7d9d6;
  --highreadability: #084a44;
  --companygreen: #119f93;
  --companygreendark: #0f8580;
  --indigo: #42047e;
  --purple: #210240;
  --heroblack: #1a1a1a;
  --jetblack: #353535;
  --granitegray: #666666;
  --mediumspring: #07f49e;
  --white: #ffffff;
  --errorred: #d64751;
  --errorred2:#E46962;
  --lightgray: #f2f2f2;
  --darkgray:#808080;
  --blogblack:#404040;
  --blue: #367cff;
  --font-family-manrope: "Manrope", sans-serif;
  --textgradient: linear-gradient(135deg, #07f49e 0%, #42047e 100%);
  --buttongradient: linear-gradient(
      72.6deg,
      rgba(66, 4, 126, 0) 0%,
      #42047e 100.71%
    ),
    linear-gradient(107.56deg, #07f49e 0%, rgba(7, 244, 158, 1) 100%), #161144;
  --button-active-gradient: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.56),
      rgba(0, 0, 0, 0.56)
    ),
    linear-gradient(92.02deg, #07f49e 0%, #42047e 100%);
}

.gradient-text {
  background: var(--textgradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.background-baby {
  background: var(--babypowder);
}
.box-shadow-admin{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);}
.neg-mt{
  margin-top: -3rem;
}
button {
  transition: all 200ms ease-in;
}
.container {
  overflow: hidden;
}
.extrabold {
  font-weight: 800;
}
.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}
.medium {
  font-weight: 500;
}
.regular {
  font-weight: 400;
}
.light {
  font-weight: 300;
}
.extralight {
  font-weight: 200;
}
h1 {
  font-size: 3.5rem;
  line-height: 4.5rem;
}
h2 {
  font-size: 3.5rem;
  line-height: 4.5rem;
}
h3 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}
h4 {
  font-size: 2rem;
  line-height: 3rem;
}
h5 {
  font-size: 1.5rem;
  line-height: 2rem;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}
small {
  font-size: 0.75rem;
 
}
.section-padding {
  padding-top: 5rem;
}
.hidemeforpc {
  display: none;
}
.showmefortablet {
  display: none;
}
.showmeformobile {
  display: none;
}
.showmeforpc {
  display: block;
}
button {
  transition: all 200ms ease-in;
}
span {
  font-size: 1.5rem;
  line-height: 2rem;
}
@media only screen and (max-width: 576px) {
  h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  h6 {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  p {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  small {
    font-size: 0.5rem;
 
  }
  span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .hidemeforpc {
    display: block;
  }
  .hidemeformobile {
    display: none;
  }
  .showmefortablet {
    display: none;
  }
  .showmeformobile {
    display: block;
  }
  .showmeforpc {
    display: none;
  }
  .section-padding {
    padding-top: 3rem;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .hidemeforpc {
    display: block;
  }
  .hidemefortablet {
    display: none;
  }
  .hidemeformobile {
    display: block;
  }
  .showmefortablet {
    display: block;
  }
  .showmeforpc {
    display: none;
  }
  h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  small {
    font-size: 0.75rem;
    
  }
  span {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .section-padding {
    padding-top: 4rem;
  }
}
