.dashboard-nav{
    background-color: var(--white);
    width: 23.33%;
    position: sticky;
    z-index: 9;
    top: 0;
}
.dashboard-nav .Link{
    color: var(--companygreen);
 }
.dashboard-nav   .items:hover{
    color: var(--companygreen);
    border-left: 4px solid var(--companygreen);
    text-decoration: none;
}
.dashboard-nav .items {
    border-left: 4px solid var(--white);
    text-decoration: none;
 color: black;
}
 .dashboard-nav .items:last-child{
   position: absolute;
   bottom: 0;
   transform: translateY(0%);
}
.navbar-x{
    background-color: var(--white);
     width: 100vw;
     top:0;
     right:0;
     padding:5px 15px 0 15px;
     transform: translateY(-5rem);
     position: sticky;
     
    }
 
    @media only screen and (max-width: 576px) {
          .navbar-x{
             width: 85vw;
             transform: translateY(-3rem);
            }
            .dashboard-nav{
                width: 38.33%;
            }
            .dashboard-nav img{
                transform: scale(0.5);
                margin-left: -25%;
            }
    }

    @media (min-width: 576px) and (max-width: 992px) {
        .navbar-x{ 
             width: 85vw;
             transform: translateY(-4rem); 
            }
            .dashboard-nav{
                width: 33.33%;
            }
            .dashboard-nav img{
                transform: scale(0.8);
            
            }
    }