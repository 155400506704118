.blogs-home-card{
    background: var(--white);
    border-radius: 16px;
    
}
.blogs-home-card h6{
    color: var(--blogblack);
}
.blogs-home-card p{
    color: var(--companygreen);
}
.warning-box{
     
  border-radius: 18px;
  border-width: 2px !important; 
  border-color: #FF0A1C !important; 
 }
.dashboard-home{
    height: 100vh;
    align-items: center;
    background-position: center center;
}
.content-section{
    height: 100vh;
  }
  .warning-box{
    margin-left: 62%;
    border:2px solid #FF0A1C;
    width:13vw;
  }
  .add-blog-card{
    transform: translate(-15%, -0%);
        display: flex;
        flex-direction: column;
        justify-content: center;
  }
  .add-blog-card input{
    border-radius: 8px;
    border: none;
    background: var(--white);
  }
  .add-blog-card select{
    border-radius: 8px;
    border: none;
    background: var(--white);
  }
  .add-blog-card input:focus  {
    border: none;
    outline: none;
  }
  .add-blog-card input:active  {
    border: none;
  }
  .add-blog-card  option{
    background-color: white;
  }
  .add-blog-card  textarea{
 width: 100%;
 min-height: 200px;
 border: none;
 outline: none;
  }

  .category-select select{
width: 50px;
background: mediumorchid;
   }
  .add-blogpost{
    width: 170px;
    bottom: 10%;
    right: 20px;
    position: absolute;
    border: none;
  background: #119F93;
  border-radius: 20px;
  color: var(--white);
   }
   .category-select{
    border-radius: 16px;
    transform: translateY(-3rem);
    border: none;
   }
 
 .blog-cards{
  width: 80vw;
 }
 .thumbnail{
  width: 100%;
 }
   .thumbnail img{
 width: 100%;
    height: 12rem;
    border-radius: 16px 16px 0 0;
   }
   .blog-home-card {
    background: var(--white);
    border-radius: 16px;
  
   }
.add-more-button{
  border-radius: 18px;
  border-width: 2px !important; 
  border-color: var(--companygreen) !important; 
color: var(--companygreen);
}
   .edit-button button{
    outline: none;
    border:none;
    background-color: white;
   }

   @media screen and (min-width:1920px) {
    .thumbnail img{
      height: 14rem;
     }
   }